import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { i18n } from "@/plugins/i18n";
import { format, parseISO } from "date-fns";
import { fetchEmpresa } from "@/api/empresas/empresas.js";
import { layoutEmpresa } from "@/views/dashboard/relatorios/config/layoutEmpresa.js";
import { fetchCheque } from "@/api/cheques/cheques.js";
import store from "@/store/modules/usuario.store.js";

export async function generateReciboTransferenciaEstoque(item_id) {
  const empresa = await fetchEmpresa(store.state.empresa.id);
  const recibo = await fetchCheque(item_id);

  return new Promise((resolve, reject) => {
    let docDefinition = {};

    let recibo_info = recibo;

    let cabecalho = {
      margin: [0, 0, 0, 0],
      table: {
        widths: ["*", "*", "*"],
        body: [
          [
            {
              // fillColor: "#F5F5F5",
              margin: [12, 5, 2, 2],
              border: [true, true, false, false],
              stack: [
                {
                  image: empresa.logo64 ? empresa.logo64 : "",
                  width: 60,
                  height: 60,
                },
              ],
            },
            {
              border: [false, true, true, false],

              stack: [
                {
                  text: empresa.nome,
                  fontSize: 12,
                  bold: true,
                },
                {},
                {
                  text: "E-mail: " + (empresa.email ? empresa.email : ""),
                },
                {
                  text:
                    "Teléfono: " + (empresa.telefone ? empresa.telefone : ""),
                },
                {
                  text:
                    "Dirección: " + (empresa.endereco ? empresa.endereco : " "),
                },
              ],
              fontSize: 9,
              alignment: "left",
              margin: [-85, 10, 0, 0],
            },
            {
              border: [false, true, true, false],
              margin: [0, 5, 0, 5],
              stack: [
                {
                  text: "RUC: " + (empresa.documento ? empresa.documento : ""),

                  alignment: "center",
                  fontSize: 10,
                },
                {
                  text: i18n.tc("global.comprovante").toUpperCase(),
                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
                {
                  text:
                    "FECHA: " +
                    format(parseISO(recibo_info.created_at), "dd/MM/yyyy"),
                  alignment: "center",
                  fontSize: 10,
                  margin: [0, 3, 0, 0],
                },
              ],
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    // ---------------------------------------------------------------------
    let detalleDelRecibo = {
      colSpan: 3,
      alignment: "center",
      border: [true, false, true, false],
      margin: [58, 10, 60, 0],
      table: {
        widths: [50, "*", 50],
        body: [
          [
            {
              text: i18n.tc("global.numero"),
              fontSize: 8,
              fillColor: "#EBEEF9",
            },
            {
              text: i18n.tc("global.banco"),
              fontSize: 8,
              fillColor: "#EBEEF9",
            },
            {
              text: i18n.tc("global.valor"),
              fontSize: 8,
              fillColor: "#EBEEF9",
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
        fillColor: function (rowIndex) {
          return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
        },
      },
    };
    if (recibo_info.cheques_filhos) {
      recibo_info.cheques_filhos.forEach((item) => {
        detalleDelRecibo.table.body.push([
          {
            text: item.numero,
            fontSize: 8,
            alignment: "center",
          },
          {
            text: item.banco_descricao,
            fontSize: 8,
            alignment: "center",
          },
          {
            text:
              item.moeda_sigla === "G$"
                ? item.moeda_sigla + " " + Vue.filter("guarani")(item.valor)
                : item.moeda_sigla + " " + Vue.filter("currency")(item.valor),
            fontSize: 8,
            alignment: "center",
          },
        ]);
      });
    }
    let corpo = {
      margin: [0, 0, 0, 0],
      table: {
        widths: [150, "*", 150],
        body: [
          [
            {
              border: [true, true, true, false],
              alignment: "center",
              fontSize: 9,
              margin: [0, 10, 0, 0],
              text:
                i18n.tc("global.devolucao").toUpperCase() +
                " " +
                i18n.tc("global.cheque").toUpperCase(),
              bold: true,
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              border: [true, false, false, false],
              text: "",
            },
            {
              border: [false, false, false, false],

              alignment: "center",
              fontSize: 9,
              margin: [0, 10, 0, 0],
              text:
                i18n.tc("global.cheque").toUpperCase() +
                " " +
                i18n.tc("global.devolucao").toUpperCase() +
                ": " +
                recibo_info.numero,
            },
            {
              border: [false, false, true, false],

              text: "",
            },
          ],
          [detalleDelRecibo, {}, {}],
          [
            {
              alignment: "start",
              text: "OBS: " + recibo_info.motivo_devolucao,
              border: [true, false, true, false],
              margin: [57, 10, 0, 0],
              fontSize: 9,
              colSpan: 3,
            },
            {},
            {},
          ],
          [
            {
              colSpan: 3,
              alignment: "justify",
              margin: [0, 10, 0, 0],
              border: [true, false, true, false],
              columns: [
                {
                  alignment: "center",
                  text: "",
                  margin: [0, 0, 0, 0],
                  fontSize: 9,
                },
                {
                  alignment: "center",
                  text:
                    "________________________________________ \n" +
                    recibo_info.quem_criou,
                  margin: [0, 0, 0, 0],
                  fontSize: 9,
                },
              ],
            },
            {},
            {},
          ],
          [
            {
              fontSize: 8,
              // fillColor: "#F5F5F5",
              margin: [8, 10, 0, 0],
              border: [true, false, true, true],
              alignment: "right",
              text: "",
              bold: true,
              colSpan: 3,
            },
            {},
            {},
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };

    let geral = [cabecalho, corpo];

    docDefinition = {
      info: {
        title: "Recibo",
        author: "PH7",
      },
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      defaultStyle: {
        font: "Roboto",
        columngap: 20,
      },
      content: [...geral],
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
